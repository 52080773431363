import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { bodyPortal } from '../../../../App';
import { ReactComponent as BitesAiLogo } from '../../../../assets/icons/bites-ai-logo.svg';
import useScreenOrientation from '../../../../hooks/useScreenOrientation';
import { useLocaleLabel } from '../../../../hooks/useTranslationOptions';
import { setTranslatedVoiceoversLocale, setTranslateVoiceoversData } from '../../../../store/bite/bite.actions';
import {
  translatedVoiceoversLocaleSelector,
  translateVoiceoversErrorSelector,
  translateVoiceoversPendingAudioSelector,
} from '../../../../store/bite/bite.selectors';
import getIsMobileDevice from '../../../../utils/getIsMobileDevice';
import { getIsRtl } from '../../../../locale/i18n';

interface IProps {
  withPlayVideoButton?: boolean;
}
const TranslateVoiceoversIsLoadingOverlay = ({ withPlayVideoButton }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRtl = getIsRtl();

  const translatedVoiceoversLocale = useSelector(translatedVoiceoversLocaleSelector);
  const translateVoiceoversError = useSelector(translateVoiceoversErrorSelector(translatedVoiceoversLocale));
  const translateVoiceoversPendingAudio = useSelector(
    translateVoiceoversPendingAudioSelector(translatedVoiceoversLocale),
  );

  const [isHidden, setIsHidden] = useState(true);
  const [isDisplayDelayedContent, setIsDisplayDelayedContent] = useState(false);
  const [isDisplayCloseButton, setIsDisplayCloseButton] = useState(false);
  const [progress, setProgress] = useState(0);
  const [logoTranslateY, setLogoTranslateY] = useState(0);

  const containerRef = useRef(null);
  const logoRef = useRef(null);
  const delayedContentTimeout = useRef(null);
  const closeButtonTimeout = useRef(null);

  const selectedLocaleLabel = useLocaleLabel(translatedVoiceoversLocale);

  const isMobileDevice = getIsMobileDevice();
  const { isScreenLandscape } = useScreenOrientation();

  const handleClose = useCallback(() => {
    dispatch(setTranslatedVoiceoversLocale(null));
    dispatch(
      setTranslateVoiceoversData({
        locale: translatedVoiceoversLocale,
        audio: null,
      }),
    );
  }, [dispatch, translatedVoiceoversLocale]);

  useEffect(() => {
    delayedContentTimeout.current = setTimeout(() => {
      setIsDisplayDelayedContent(true);
      setLogoTranslateY(0);
    }, 3000);

    closeButtonTimeout.current = setTimeout(() => {
      setIsDisplayCloseButton(true);
    }, 6000);

    return () => {
      clearTimeout(delayedContentTimeout.current);
      clearTimeout(closeButtonTimeout.current);
    };
  }, []);

  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const logoRect = logoRef.current.getBoundingClientRect();

    // place logo in the middle of the screen
    // take into account current logo position
    setLogoTranslateY((containerRect.height - logoRect.height) / 2 - logoRect.top);

    setIsHidden(false);
    setProgress(100);
  }, []);

  const handlePlayVideo = useCallback(() => {
    dispatch(
      setTranslateVoiceoversData({
        locale: translatedVoiceoversLocale,
        audio: translateVoiceoversPendingAudio,
      }),
    );
  }, [dispatch, translateVoiceoversPendingAudio, translatedVoiceoversLocale]);

  // const startRef = useRef(Date.now());
  useEffect(() => {
    if (!translateVoiceoversPendingAudio) {
      return;
    }

    if (
      withPlayVideoButton
      // && Date.now() - startRef.current >= 1000
    ) {
      return;
    }

    handlePlayVideo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateVoiceoversPendingAudio]);

  return createPortal(
    <S.Container isVisible={!isHidden} ref={containerRef}>
      <S.Logo translateY={logoTranslateY} ref={logoRef}>
        <BitesAiLogo />
      </S.Logo>
      <S.DelayedContent isVisible={isDisplayDelayedContent}>
        {translateVoiceoversError ? (
          <S.Error isRtl={isRtl}>{t('components.translations.somethingWentWrong')}</S.Error>
        ) : withPlayVideoButton && translateVoiceoversPendingAudio ? (
          <>
            <S.Title isRtl={isRtl}>
              {t('components.translations.voiceovers.translatingOverlay.translated', {
                language: selectedLocaleLabel,
              })}
            </S.Title>
            <S.DoneButton isRtl={isRtl} onClick={handlePlayVideo}>
              {t('components.translations.voiceovers.translatingOverlay.playVideo', {
                language: selectedLocaleLabel,
              })}
            </S.DoneButton>
          </>
        ) : (
          <>
            <S.Title isRtl={isRtl}>
              {t('components.translations.voiceovers.translatingOverlay.delayedTitle', {
                language: selectedLocaleLabel,
              })}
            </S.Title>
            {isMobileDevice && isScreenLandscape ? null : (
              <S.Subtitle isRtl={isRtl}>
                {t('components.translations.voiceovers.translatingOverlay.delayedSubtitle')}
              </S.Subtitle>
            )}
            <S.LoaderContainer>
              <S.Loader progress={progress} />
            </S.LoaderContainer>
          </>
        )}
      </S.DelayedContent>
      {!translateVoiceoversPendingAudio && (
        <S.Button isVisible={isDisplayCloseButton} isRtl={isRtl} onClick={handleClose}>
          {translateVoiceoversError
            ? t('components.translations.close')
            : t('components.translations.voiceovers.translatingOverlay.cantWait')}
        </S.Button>
      )}
    </S.Container>,
    bodyPortal.current,
  );
};
export default memo(TranslateVoiceoversIsLoadingOverlay);

const S = {
  Container: styled.div<{
    isVisible: boolean;
  }>`
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    background-color: ${({ theme }) => theme.colors.transparentBlack3};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.5s;
  `,
  Logo: styled.div<{
    translateY: number;
  }>`
    width: 179px;
    height: 88px;
    transform: translateY(${({ translateY }) => translateY}px);
    transition: transform 0.5s;
  `,
  DelayedContent: styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    max-width: 300px;
    text-align: center;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.5s;
  `,
  Error: styled.div<{ isRtl: boolean }>`
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.failRed};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  Title: styled.div<{ isRtl: boolean }>`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  Subtitle: styled.div<{ isRtl: boolean }>`
    margin-top: 24px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  Description1: styled.div<{ isRtl: boolean }>`
    margin-top: 32px;
    font-size: 14px;
    font-weight: 700;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  Description2: styled.div<{ isRtl: boolean }>`
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  LoaderContainer: styled.div`
    margin-top: 24px;
    width: 106px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    overflow: hidden;
  `,
  Loader: styled.div<{ progress: number }>`
    width: ${({ progress }) => progress}%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    transition: width 180s ease-out;
  `,
  Button: styled.div<{
    isVisible: boolean;
    isRtl: boolean;
  }>`
    position: absolute;
    bottom: 24px;
    font-size: 13px;
    font-weight: 400;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
    cursor: pointer;
    transform: translateY(${({ isVisible }) => (isVisible ? 0 : 100)}px);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: all 0.5s ease-in-out;
  `,
  DoneButton: styled.div<{ isRtl: boolean }>`
    margin-top: 24px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    cursor: pointer;
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
};
