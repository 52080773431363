import { datadogRum } from '@datadog/browser-rum';
import { getEnvName } from '../../utils/env';
import { datadogLogs } from '@datadog/browser-logs';

const dDClientTokenId = 'pub307685435e8aef0e237bc9aaa67ea339';
const dDApplicationId = '7d750ec1-73de-49fe-ad04-2a7d091533fb';
const dDSite = 'datadoghq.com';
const dDService = 'frontliner';

datadogLogs.init({
  clientToken: dDClientTokenId,
  site: dDSite,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: dDService,
  env: getEnvName(),
});

datadogLogs.addLoggerGlobalContext('host', window.location.host);

export const datadogLogger = datadogLogs.logger;

datadogRum.init({
  applicationId: dDApplicationId,
  clientToken: dDClientTokenId,
  site: dDSite,
  service: dDService,
  env: getEnvName(),
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [/api.small-bites.com/, /api.mybiteshares.com/],
});

datadogRum.startSessionReplayRecording();
